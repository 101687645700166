<script>
export default {
  name : 'Modal',
  props : ["modalData"],
  data() {
    return {
      data : this.modalData,
      showModal : false,
    }
  },
  mounted() {
//    console.log(this.modalData)
    this.showModal = true;
  },
  methods: {
    openModal() {
      window.history.pushState({}, '', '#modal');
      window.onpopstate = history.onpushstate = () => {
        this.showModal = false
      }
    },

    closeModal() {
      this.$emit('close');
      window.history.back()
    },
  }
}
</script>

<template>
  <b-modal
      v-model="showModal"
      id="modal-box"
      @show="openModal"
      @hidden="closeModal"
      centered
      title="상세정보"
      title-class="font-16 fw-bold"
      hide-footer
  >
    <div class="row mb-2 align-items-center">
      <div class="my-2">
        <div class="mb-2 fw-semibold">전화번호</div>
        <div class="form-control bg-light">{{ data.phone }}&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-2 fw-semibold">메시지</div>
        <div class="form-control bg-light">{{ data.message }}&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-2 fw-semibold">제목</div>
        <div class="form-control bg-light">{{ data.title }}</div>
      </div>
      <div class="mb-2">
        <div class="mb-1 fw-semibold">코드</div>
        <div class="form-control bg-light">{{ data.code }}</div>
      </div>
      <div class="my-2">
        <div class="mb-1 fw-semibold">전송결과</div>
        <div class="form-control bg-light"><span class="text-dark" v-if="data.success">성공</span><span class="text-danger" v-else>실패 ({{ data.error }})</span></div>
      </div>
      <div class="my-2">
        <div class="mb-1 fw-semibold">전송일시</div>
        <div class="form-control bg-light">{{ data.created_at }}&nbsp;</div>
      </div>
    </div>
  </b-modal>
</template>
